module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"myaenterprise","defaultLang":"es-mx","path":"/preview","previews":true,"langs":["es-mx","en-us"],"pages":[{"type":"Homepage","match":"/:lang?","path":"/homepage/preview","component":"/Users/paulinagavilan/Desktop/myaenterprise/src/templates/homepage.js","langs":["es-mx","en-us"]},{"type":"Page","match":"/:lang?/:uid","path":"/page/preview","component":"/Users/paulinagavilan/Desktop/myaenterprise/src/templates/page.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
